<template>
  <!-- Content -->
  <div>
    <unshare-modal
      v-model="isUnshareModalVisible"
      model-type="success-story"
      @remove-item="handleRemoveItem"
    />
    <b-spinner
      v-if="isLoading"
      class="center-x my-3"
      small
      variant="primary"
      type="grow"
    />
    <b-card v-if="item && isLoading === false && canView" no-body>
      <b-dropdown
        id="dropdown-dropleft"
        variant="link"
        right
        class="dropdown-user"
        no-caret
      >
        <template #button-content class="edit-button">
          <feather-icon
            v-if="canEdit"
            icon="Edit2Icon"
            size="20"
            class="text-primary edit-icon"
          />
        </template>

        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item v-b-modal.modal-edit-success-story>
          {{
            $t("edit-dropdown.details")
          }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="isClassifiersModalVisible = true"
        >
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="isCustomFieldsModalVisible = true"
        >
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>

      <feather-icon
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        class="text-primary share-icon"
        @click="isShareModalVisible = true"
      />
      <div class="trash-icon">
        <feather-icon
          v-if="canDelete"
          v-b-tooltip.hover.bottom
          icon="Trash2Icon"
          :title="$t('unshare.tooltip')"
          size="20"
          class="text-primary"
          @click="isUnshareModalVisible = true"
        />
      </div>
      <b-card-body>
        <b-row class="my-2 align-items-start">
          <!-- LEFT: Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center px-4">
              <b-img
                :src="item.bgURL || ProductPlaceholder"
                :alt="`Image of ${item.name}`"
                class="product-img"
                style="border-radius: 1.2rem"
                fluid
              />
            </div>
          </b-col>

          <!-- RIGHT: Details -->
          <b-col cols="12" md="7">
            <!-- Name -->
            <h3 class="min-height-3">
              {{ item.name }}
            </h3>
            <!--  Description -->
            <b-card-text>
              <span
                v-if="item.headline"
                class="html-text-ellipsis-2"
                v-html="item.headline"
              />
              <span class="content-container" v-html="item.description" />
            </b-card-text>
            <!--Custom fields-->
            <div v-for="(field, index) in item.custom" :key="index">
              <b-row
                v-if="
                  field.value ||
                    (typeof field.value !== 'string' && !field.value)
                "
                class="mb-1"
              >
                <b-col class="font-weight-bold">
                  {{ getName(field) }}:
                </b-col>
                <b-col>
                  {{ getValue(field.value) }}
                </b-col>
              </b-row>
            </div>
            <!-- Classifiers -->
            <template
              v-if="classifiers && classifiers.length > 0"
              v-key="classifiers"
              class="mb-50"
            >
              <div v-for="(value, name) in sortedClassifiers" :key="value.key">
                <div class="w-100">
                  {{ name }}:
                </div>
                <span v-for="v in value" :key="v.index">
                  <b-badge class="mb-50 mr-50" :style="classifierStyle(v)">
                    {{
                      translateTranslationTable(
                        $store.state.locale.currentLocale,
                        v.name
                      )
                    }}
                  </b-badge>
                </span>
              </div>
            </template>
          </b-col>
        </b-row>
      </b-card-body>
      <classifiers-modal
        v-model="isClassifiersModalVisible"
        :item-key="item.key"
        morph-type="successstories"
        model-type="success-story"
        :classifiers="classifiers"
        @profileUpdated="fetchClassifiers"
      />
      <!-- sortedCustomFields -->
      <customfields-modal
        v-model="isCustomFieldsModalVisible"
        :item="item"
        morph-type="success-story"
        @profileUpdated="updateCustomFields"
      />
      <!-- Edit Success Story -->
      <b-modal
        id="modal-edit-success-story"
        :title="$t('successstories.modal-edit-title')"
        cancel-variant="outline-secondary"
        :ok-title="$t('form.actions.save')"
        :cancel-title="$t('form-create-item.cancel')"
        centered
        size="lg"
        @ok="handleEditSuccessStory"
      >
        <b-form>
          <b-form-group>
            <label for="title"> {{ $t("form-create-item.title") }}:</label>
            <b-form-input id="name" v-model="itemInput.name" type="text" />
          </b-form-group>
          <b-form-group>
            <label for="headline">
              {{ $t("form-create-item.headline") }}:</label>
            <b-form-input
              id="headline"
              v-model="itemInput.headline"
              type="text"
            />
          </b-form-group>
          <b-form-group>
            <label
              class="mt-2"
              for="description"
            >{{ $t("form-create-item.description") }}:</label>
            <quill-editor v-model="itemInput.description" />
          </b-form-group>

          <p class="mt-3">
            {{ $t("form-create-item.image") }}:
          </p>
          <div class="border rounded p-2">
            <p class="mb-50">
              {{ $t("form-create-item.image-select") }}
            </p>
            <b-media
              no-body
              vertical-align="center"
              class="d-flex align-items-center flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  ref="refPreviewEl"
                  :src="imageSrc"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                  style="background: #c0c0c0"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-inline-block d-flex align-items-center pl-2">
                  <b-form-file
                    v-model="imageChallenge"
                    class="pointer"
                    :accept="FileTypes"
                    :placeholder="$t('form-create-item.image-placeholder')"
                    :browse-text="$t('form-create-item.image-button')"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-form>
      </b-modal>
      <share-modal
        v-model="isShareModalVisible"
        :can-share="canShare"
        :item-name="item.name"
        model-type="successstories"
        :model-key="item.key"
        type=""
      />
    </b-card>
    <b-card v-else-if="!canView" class="horizontal-placeholder">
      <b-row>
        <b-col cols="12">
          <img :src="successPlaceholder">
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("no-permisions.message") }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import ProductPlaceholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import SuccessPlaceholder from '@/assets/images/placeholders/light/success_stories.svg';
import FileTypes from '@/@core/constants/FileTypes';
import { dateValidator } from '@/@core/utils/validations/validators';
import { AppIDLUT } from '@copernicsw/community-common';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'SuccessStoriesDetails',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    quillEditor,
    ClassifiersModal,
    CustomfieldsModal,
    ShareModal,
    UnshareModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isShareModalVisible: false,
      imageChallenge: null,
      imageSrc: null,
      isUnshareModalVisible: false,
    };
  },
  computed: {
    cFields() {
      return this.$store.getters.customFields;
    },
    FileTypes() {
      return FileTypes;
    },
    item() {
      const iiitem = this.$store.getters.item('successstories', this.id);
      return iiitem;
    },
    itemInput() {
      const item = this.$store.getters.item('successstories', this.id);
      return item;
    },
    ProductPlaceholder() {
      return ProductPlaceholder;
    },
    successPlaceholder() {
      return SuccessPlaceholder;
    },
    classifiersData() {
      if (this.$store.getters.classifiers.successstory) {
        return this.$store.getters.classifiers.successstory.unpaginated;
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    canEdit() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.collective.isStaff
        || checkPermissions(
          'update',
          'success-story',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canDelete() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.collective.isStaff
        || checkPermissions(
          'delete',
          'success-story',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canShare() {
      return checkPermissions(
        'share',
        'success-story',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    canView() {
      return checkPermissions(
        'view',
        'success-story',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    /*     collective() {
      return this.$store.state.collective.collective;
    }, */
    appId() {
      return 75;
    },
    apps() {
      return this.$store.getters.apps;
    },
    app() {
      if (this.apps) {
        return this.apps.apps[this.appId] || {};
      }
      return {};
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    await this.fetchCustomFields();
    await this.fetchClassifiers();
    this.updateBreadcrumbs();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'successstories',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          count: 100,
          orderByDate: -1,
        },
      });
    },
    async fetchCustomFields() {
      await this.$store.dispatch("fetchCustomFields", {
        modelType: "success-story",
      });
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async handleEditSuccessStory() {
      try {
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'successstories',
            requestConfig: {
              key: this.id,
              projectKey: this.id,
              name: this.itemInput.name,
              headline: this.itemInput.headline,
              description: this.itemInput.description,
            },
          },
          file: this.imageChallenge,
        });
        this.item.bgURL = result.bgURL;
        this.itemInput = {};
        this.updateBreadcrumbs();
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'successstory',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          successstoryKey: this.item.key,
        },
      });
      this.isLoading = false;
    },
    translateTranslationTable,
    translatedFieldName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.name,
      );
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    async updateCustomFields() {
      await this.fetchData();
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              this.app.customizationName
              ,
            ) || this.$t('successstories.title'),
          to: { name: 'success-stories' },
        },
        {
          text: this.item.name
            ? this.item.name
            : 'successstories.breadcrumb-text.details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    getName(field) {
      let name = '';
      this.cFields.map((item) => {
        if (field.key === item.key) {
          name = item.name;
        }
      });
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        name,
      );
    },
    async handleCustomFields() {
      await this.fetchData();
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split('-').reverse().join('-');
      }
      if (value === true) {
        return this.$t('yes');
      }
      if (value === false) {
        return this.$t('no');
      }
      return value;
    },
    async handleRemoveItem(response) {
      const item = {
        key: this.id,
      };
      if (response === true) {
        const itemData = {
          itemType: 'success-stories',
          storedKey: "success-stories",
          modelName: 'success-story',
          tableName: 'work_items',
          key: item.key,
          modelKey: item.key
        };
        const deleteItemGenericResult = await this.$store.dispatch('deleteItemGeneric', {
          item: itemData,
          customName: "successstories",
          noSet: true,
        });
        console.log('deleteItemGenericResult', deleteItemGenericResult);
          // await this.$store.dispatch('unshareSuccessStory', {
          //   item,
          //   type: 'SuccessStory',
          //   tableName: 'successstories',
          // });
        if(deleteItemGenericResult) {
          // notify success
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.success-story'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });

          // get all success stories again
          await this.fetchData();

          // and go to index
          this.$router.push({
            name: 'success-stories',
            params: { communityId: this.$route.params.communityId },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.success-story'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.content-container > img {
  width: 80%;
}
.share-icon {
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
}
.edit-icon {
  position: absolute;
  top: -40px;
  right: 30px;
  cursor: pointer;
}
.trash-icon {
  position: absolute;
  top: -40px;
  right: 70px;
  cursor: pointer;
}
</style>
